/* eslint-disable camelcase */
import { createNamespacedHelpers } from 'vuex'
import get from 'lodash/get'
import envMixin from './envMixin'

const { mapGetters } = createNamespacedHelpers('auth')

export default {

  mixins: [envMixin],
  computed: {
    ...mapGetters(['user', 'generalConfigs']),

    isExclusivePartner() {
      return this.generalConfigs?.system === 'ADREACH'
    },

    isPancakeUser() {
      return this.user.data.pancakeUserId
    },

    userName() {
      return get(this.user, ['data', 'fullName'], '')
    },

    userEmail() {
      return this.user?.data?.email
    },

    userId() {
      return this.user?.data?.localUserId
    },

    userStep() {
      return this.user?.data?.onboardingStep
    },
  },

  methods: {
    phoneNumber() {
      const contactInfo = get(this.user, ['data', 'contactInfo'], {})
      if (contactInfo?.phone) {
        const phoneElm = contactInfo.phone.split('|')
        if (phoneElm.length === 3) {
          return `+${phoneElm[1]}${phoneElm[2]}`
        }
      }
      return ''
    },

    navigateToPancake() {
      const pancakePageId = localStorage.getItem('pancakePageId')
      if (pancakePageId) {
        window.open(`https://pancake.vn/${pancakePageId}/setting/tools?select_setting=tiktok_ads`)
      } else {
        window.open('https://pancake.vn/dashboard')
      }
    },
  },
}
